<template>
    <div class=container>
        
        <td height="100%" align="left" valign="baseline" bgcolor="#FFFFFF">      <div align="center">        <table width="100%" border="0" cellspacing="30" cellpadding="0">
                <tbody><tr>
                    <td><div align="center"><font size="+2">Gallery of Separation Results</font></div></td>
                </tr>
                <tr>
                    <td>Here, we show several examples of our separation results. In each case, we mention the separation method we have used. In all cases, the scene image is the sum of the computed direct and global images. However, to bring out the details, for some of the scenes we have brightened the direct and global images by a scale factor (between 1 and 2) with respect to the scene image. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br>
       </td>
                </tr>
                        <router-link :to="{path:'/projects/categories/project', query:{cid:'Computational Imaging',pid:'Fast Separation of Direct and Global Images'}}">(Back to Main Project Page) </router-link>
                <tr>
                    <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                    <tbody><tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                            <td><strong>Complex Scene</strong></td>
                            <td><div align="right"><strong>Method : Checkerboard<br>
                            </strong></div></td>
                        </tr>
                        </tbody></table>
                        <div align="right"></div></td>
                        </tr>
                    <tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                            <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Validation_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Validation_Scene.jpg" width="203" height="152" border="0"></a><br>  
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                            <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Validation_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Validation_Direct.jpg" width="203" height="152" border="0"></a><br>
                            <span class="style1">.</span><br>
                            <strong>Direct</strong></div></td>
                            <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Validation_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Validation_Global.jpg" width="203" height="152" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Global</strong></div></td>
                        </tr>
                        </tbody></table>
                        <table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                            <td>This  example includes a variety of global illumination effects. Strong  global illumination effects arise due to diffuse interreflections  between the white walls, specular interreflections within the plastic  cup, volumetric scattering due to the milky water in the cup,  subsurface scattering in the candles and in the marble&nbsp; object and  translucency of the frosted glass.</td>
                            </tr>
                            <tr>
                            <td>&nbsp;</td>
                            </tr>
                        </tbody></table></td>
                        </tr>
                    <tr>
                        <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                        <tbody><tr>
                            <td width="32%"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Validation_Video.jpg" width="203" height="152"></td>
                            <td width="68%" valign="top"><p><strong>Captured Video:</strong> This video shows the images of the scene captured by shifting a high frequency checkerboard pattern. <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Validation_640x480.mpg">MPEG</a>, <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Validation_640x480.mov">Quicktime</a></p></td>
                        </tr>
                        </tbody></table></td>
                        </tr>
                    </tbody></table></td>
                </tr>
                
                
                <tr>
                    <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                        <tbody><tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="47%"><strong>Water tub with objects</strong></td>
                                <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                                </strong></div></td>
                            </tr>
                            </tbody></table>
                            <div align="right"></div></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Tub_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Tub_Scene.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Scene</strong></div></td>
                                <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Tub_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Tub_Direct.jpg" width="202" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Direct</strong></div></td>
                                <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Tub_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Tub_Global.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Global</strong></div></td>
                            </tr>
                            </tbody></table>
                            <table width="100%" border="0" cellspacing="10" cellpadding="0">
                                <tbody><tr>
                                <td>This  scene includes objects in a tub filled with water. Since the water is  clear it acts as a fully transparent medium that does not influence the  scattering effects. It is worth noting that the computed direct image  looks like a synthetic image rendered using a single-bounce rendering  package such as OpenGL. All the interreflections between the sink and  the objects are observed in the global image. Notice the strong  interreflections at the edges and corners of the sink and the occluding  boundaries of the curved objects.</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                </tr>
                            </tbody></table></td>
                        </tr>
                    </tbody></table></td>
                </tr>
                <tr>
                    <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                        <tbody><tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="47%"><strong>Textured Fabric</strong></td>
                                <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                                </strong></div></td>
                            </tr>
                            </tbody></table>
                            <div align="right"></div></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Fabric_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image5541.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Scene</strong></div></td>
                                <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Fabric_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image4901.jpg" width="202" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Direct</strong></div></td>
                                <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Fabric_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image5051.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Global</strong></div></td>
                            </tr>
                            </tbody></table>
                            <table width="100%" border="0" cellspacing="10" cellpadding="0">
                                <tbody><tr>
                                <td>In  this example of a sheet cloth with folds, the global image includes  interreflections within the folds as well as translucency effects due  to the fine spaces between the weaves of the fabric.</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                </tr>
                            </tbody></table></td>
                        </tr>
                    </tbody></table></td>
                </tr>
                


                <tr>
                    <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                        <tbody><tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td><strong>Wooden Blocks</strong></td>
                                <td><div align="right"><strong>Method : Checkerboard</strong></div></td>
                            </tr>
                            </tbody></table>
                            <div align="right"></div></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Blocks_Sum.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Blocks_Sum.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Scene</strong></div></td>
                                <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Blocks_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Blocks_Direct.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Direct</strong></div></td>
                                <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Blocks_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Blocks_Global.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Global</strong></div></td>
                            </tr>
                            </tbody></table>
                            <table width="100%" border="0" cellspacing="10" cellpadding="0">
                                <tbody><tr>
                                <td>These  colored wooden blocks have both diffuse and specular components. The  computed global image shows the complex interreflections and color  bleeding between the blocks. Again, similar to the V-groove scene, the  interreflection is stronger near the concavities.</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                </tr>
                            </tbody></table></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                            <tbody><tr>
                                <td width="32%"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Novel_Blocks.jpg" width="203" height="152"></td>
                                <td width="68%" valign="top"><strong>Novel Images: </strong>This video shows novel images�generated by simple addition of differently weighted direct and global images. Though the resulting images are physically impossible, they exaggerate or de-emphasize the optical interactions between the blocks. <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Blocks_640x480.mpg">MPEG</a>,  <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Blocks_640x480.mov">Quicktime</a></td>
                            </tr>
                        </tbody></table></td>
                        </tr>
                    </tbody></table></td>
                </tr>
                
                
                <tr>
                    <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                        <tbody><tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="47%"><strong>Cup : Coin in Milky Water</strong></td>
                                <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                                </strong></div></td>
                            </tr>
                            </tbody></table>
                            <div align="right"></div></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Milk_Glass_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Milk_Glass_Scene.jpg" width="202" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Scene</strong></div></td>
                                <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Milk_Glass_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Milk_Glass_Direct.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Direct</strong></div></td>
                                <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Milk_Glass_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Milk_Glass_Global.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Global</strong></div></td>
                            </tr>
                            </tbody></table>
                            <table width="100%" border="0" cellspacing="10" cellpadding="0">
                                <tbody><tr>
                                <td>In  this example, the global image includes the volumetric scattering of  light by the milky water (referred to as "airlight" in atmospheric  optics) in the cup as well as the secondary illumination of the coin by the milky water. The direct image includes the specular  highlights on the coin due to direct illumination of the coin by  the source. This component is attenuated by the milky water as it makes  its way to the camera.</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                </tr>
                            </tbody></table></td>
                        </tr>
                    </tbody></table></td>
                </tr>
                <tr>
                    <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                        <tbody><tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td><strong>Kitchen Sink : Objects in Milky Water</strong></td>
                                <td><div align="right"><strong>Method : Checkerboard</strong></div></td>
                            </tr>
                            </tbody></table>
                            <div align="right"></div></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Kitchen_Sink_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Kitchen_Sink_Scene.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Scene</strong></div></td>
                                <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Kitchen_Sink_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Kitchen_Sink_Direct.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Direct</strong></div></td>
                                <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Kitchen_Sink_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Kitchen_Sink_Global.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Global</strong></div></td>
                            </tr>
                            </tbody></table>
                            <table width="100%" border="0" cellspacing="10" cellpadding="0">
                                <tbody><tr>
                                <td>This  scene of a kitchen sink includes objects immersed in milky water which  acts as a participating medium. The global effects are mainly due to  volumetric scattering by the milky medium which not only scatters light  from the source but also illuminates the objects as a secondary source.  It is interesting to note the faint colored glow around the objects in  the global image due to light received by the milky water from the  objects. Note that the direct image is devoid of any volumetric  scattering and hence appears like an image of the objects taken in a  clear medium.</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                </tr>
                            </tbody></table></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                            <tbody><tr>
                                <td width="32%" valign="top"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Novel.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Novel.jpg" width="203" height="152" border="0"></a><br>
                                    <br>
                                    <img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image7831.jpg" width="60" height="56"> <img src="https://cave.cs.columbia.edu/old/projects/separation/Separation_GalleryTwo_files/image8081.jpg" width="59" height="56"> </div></td>
                                <td width="68%" valign="top">
        <strong>Novel Image:</strong> To  generate this novel image, the dark regions of the global image were  used to estimate the brightness of the milk. It is assumed to be  constant over the scene and is removed from the global image to obtain  the radiance of the objects due to illumination by the milk. The ratios  of brightnesses in the direct image and the milk illumination image  were tabulated. Then, the direct images of two other objects (green  fruit and orange pot) were separately captured and their milk  illumination images were computed using the tabulated ratios. The  direct, milk illumination and milk brightness components of these new  objects were then added and the objects were inserted into the scene  image. Notice how the inserted objects include not only the effects of  scattering by the milk but also secondary illumination by the milk.</td>
                            </tr>
                        </tbody></table></td>
                        </tr>
                    </tbody></table></td>
                </tr>
                
                

                <tr>
                    <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                        <tbody><tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="47%"><strong>Translucent Balls</strong></td>
                                <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                                </strong></div></td>
                            </tr>
                            </tbody></table>
                            <div align="right"></div></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Color_Balls_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Color_Balls_Scene.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Scene</strong></div></td>
                                <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Color_Balls_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Color_Balls_Direct.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Direct</strong></div></td>
                                <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Color_Balls_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Color_Balls_Global.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Global</strong></div></td>
                            </tr>
                            </tbody></table>
                            <table width="100%" border="0" cellspacing="10" cellpadding="0">
                                <tbody><tr>
                                <td>The  balls in this scene exhibit very strong subsurface scattering which  causes them to "glow" under virtually any illumination. We see that all  the subsurface scattering is captured in the global image. Perhaps, due  to strong multiple scattering, the global images of the balls have very  little shading, causing the balls to appear like flat discs  (particularly, the green ball). On the other hand, the direct component  reveals the spherical shapes of the balls and the roughness of their  surfaces.</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                </tr>
                            </tbody></table></td>
                        </tr>
                    </tbody></table></td>
                </tr>
                
                
                <tr>
                    <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                        <tbody><tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td><strong>Candles</strong></td>
                                <td><div align="right"><strong>Method : Checkerboard<br>
                                </strong></div></td>
                            </tr>
                            </tbody></table>
                            <div align="right"></div></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Candle_Sum.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Candle_Sum.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Scene</strong></div></td>
                                <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Candle_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Candle_Direct.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Direct</strong></div></td>
                                <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Candle_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Candle_Global.jpg" width="203" height="152" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Global</strong></div></td>
                            </tr>
                            </tbody></table>
                            <table width="100%" border="0" cellspacing="10" cellpadding="0">
                                <tbody><tr>
                                <td>The  color of these candles is mainly due to subsurface scattering as can be  seen in the global image. On the other hand, the direct image primarily  includes specular reflections from the surfaces of the candles.</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                </tr>
                            </tbody></table></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                            <tbody><tr>
                                <td width="32%"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Candle_Novel.jpg" width="203" height="152"></td>
                                <td width="68%" valign="top"><p><strong>Novel Images:</strong> This  video shows novel images generated by changing the hues of the candles  in the global image and adding it back to the direct image.<br>
                                <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Candles_640x480.mpg">MPEG</a>, <a href="https://cave.cs.columbia.edu/old/projects/separation/videos/Candles_640x480.mov">Quicktime</a></p>                        </td>
                            </tr>
                        </tbody></table></td>
                        </tr>
                    </tbody></table></td>
                </tr>
                
                
                
                <tr>
                    <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                        <tbody><tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="47%"><strong>Pebbles</strong></td>
                                <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                                </strong></div></td>
                            </tr>
                            </tbody></table>
                            <div align="right"></div></td>
                        </tr>
                        <tr>
                        <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                            <tbody><tr>
                                <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Marble_Pebbles_Scene.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Marble_Pebbles_Scene.jpg" width="202" height="153" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Scene</strong></div></td>
                                <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Marble_Pebbles_Direct.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Marble_Pebbles_Direct.jpg" width="203" height="153" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Direct</strong></div></td>
                                <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Marble_Pebbles_Global.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Marble_Pebbles_Global.jpg" width="203" height="153" border="0"></a><br>
                                        <span class="style1">.</span><br>
                                        <strong>Global</strong></div></td>
                            </tr>
                            </tbody></table>
                            <table width="100%" border="0" cellspacing="10" cellpadding="0">
                                <tbody><tr>
                                <td>Here we show the separation results for the pebbles sample taken from the <a href="http://www1.cs.columbia.edu/CAVE/software/curet/index.php">CURET database</a> . Notice how the direct image look like a real image of dark pebbles.  The global image includes subsurface scattering within individual  pebbles as well as the complex interreflections between neighboring  pebbles.</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                </tr>
                            </tbody></table></td>
                        </tr>
                    </tbody></table></td>
                </tr>
                
                <tr>
                    <td>&nbsp;</td>
                </tr>
        </tbody></table>
        <br>
        <br>
        </div>
        </td>
    </div>
</template>

<script>
export default {
    name:'test'
}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
}
</style>